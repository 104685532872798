.interview-completed-invite-email-wrapper {
  .interview-completed-alert-wrapper {
    @apply flex flex-col items-center text-center justify-center mb-12;
    @apply md:flex-row md:items-start md:text-left;

    .icon-wrapper {
      @apply flex items-center justify-center w-[72px] h-[72px] mt-4 mr-6 bg-success text-white 
        rounded-full;
    }

    .text-content-wrapper {
      @apply w-full md:w-[600px] mt-4 md:mt-0 block;
    }
  }
}
