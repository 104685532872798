.form-group {
  @apply relative mb-7;

  .form-label,
  .form-label-checkbox {
    @apply block font-medium text-sm text-gray-900;
  }

  .form-label {
    @apply mb-1;
  }

  .form-input,
  .form-select {
    @apply block border border-gray-300 rounded-md focus:outline-violet w-full py-2.5 px-2;

    &.error {
      @apply border border-danger;

      &:focus {
        @apply focus:outline-danger;
      }
    }

    &.disabled {
      @apply bg-gray-200;
    }
  }

  .form-select {
    -moz-appearance: none;
    -webkit-appearance: none;
    appearance: none;
    background-image: url('./../assets/images/chevron.png');
    background-repeat: no-repeat;
    background-position: calc(100% - 12px) center;
    background-size: 12px;
  }

  .form-checkbox {
    @apply flex items-center bg-gray-100 p-3 rounded-md;

    input {
      @apply h-5 w-5 mr-3;
    }
  }

  &.form-radio {
    @apply mb-2;

    input {
      @apply appearance-none relative rounded-full mt-1 mr-3 h-4 w-4 transition duration-150 align-top cursor-pointer;
      @apply border border-gray-400 checked:bg-white checked:border-violet focus:ring-2 focus:ring-violet-extralight;

      &:checked {
        &:after {
          @apply content-[''] absolute w-2 h-2 top-[calc(50%-0.25rem)] left-[calc(50%-0.25rem)] rounded-full bg-violet;
        }
      }
    }
  }

  .form-error {
    @apply text-danger absolute bottom-[-24px] mt-0.5;
  }

  .help-text {
    @apply text-gray-500 mt-1.5;
  }

  &.form-password {
    .form-input {
      @apply pr-10;
    }

    .visibility-toggler-icon {
      @apply absolute text-gray-500 top-[36px] right-[12px] cursor-pointer duration-150 hover:text-gray-700;
    }
  }
}
