@use "../../styles/media" as *;

.loader-container {
	--overshoot-cubic-bezier: cubic-bezier(0.785, 0.135, 0.15, 0.86);
	--logo-circle-size: 58px;
	--animation-name: hubert-right-spin;

	@include media("mobile") {
		--logo-circle-size: 38px;
	}

	@apply h-screen bg-white;
	@apply flex items-center justify-center gap-8;
	@apply mx-2;

	.logo {
		@apply flex items-center gap-2;

		@include media("mobile") {
			gap: 5px;
		}
	}

	@include media("mobile") {
		@apply gap-4;
	}

	.hubert-right {
		@apply h-[var(--logo-circle-size)] w-[var(--logo-circle-size)];
		@apply rounded-full bg-black;
		animation: var(--animation-name) 1.5s var(--overshoot-cubic-bezier) infinite
			alternate;

		@include media("no-motion") {
			animation: none;
		}
	}

	.hubert-left {
		--animation-name: hubert-left-spin;
		@apply flex h-16 w-16 items-center justify-center rounded-lg bg-black;
		animation: var(--animation-name) 1.5s var(--overshoot-cubic-bezier) infinite
			alternate;

		@include media("mobile") {
			@apply h-10 w-10;
		}

		@include media("no-motion") {
			animation: none;
		}

		.inner {
			@apply h-[var(--logo-circle-size)] w-[var(--logo-circle-size)] rounded-full bg-white;
		}
	}
}

@keyframes hubert-left-spin {
	from {
		transform: translate(56.5%) rotate(0deg);
	}
	to {
		transform: translate(0) rotate(-360deg);
	}
}

@keyframes hubert-right-spin {
	from {
		transform: translate(-56.5%) rotate(0deg) scale(1.01);
	}
	to {
		transform: translate(0) rotate(360deg);
	}
}
