.dashboard-wrapper {
  @apply sm:pt-5;

  .your-career-wrapper {
    @apply relative bg-neutral-purple px-5 pt-[80px] mx-[-20px] pb-12;
    @apply sm:pt-[120px] sm:px-[60px] sm:rounded-2xl sm:mx-0;

    .illustration {
      @apply absolute hidden h-auto top-5 right-8;
      @apply sm:w-[120px] md:w-[160px] lg:w-[200px] sm:block;
    }

    .interview-status-section {
      @apply grid grid-cols-2 gap-5 justify-between pt-10;

      .section {
        @apply col-span-full xl:col-span-1 bg-white p-6 rounded-2xl;
        
        .section-body {
          @apply grid grid-cols-12;

          .left-body-section {
            @apply col-span-full sm:col-span-7;
          }

          .right-body-section {
            @apply col-span-full flex flex-col items-end justify-between pt-2;
            @apply sm:col-span-5 sm:pt-0;

            .created-at {
              @apply self-start sm:self-auto;
            }
          }

          &.no-ongoing-interviews {
            @apply flex items-start justify-start;

            img {
              @apply w-5 h-auto mr-3 mt-1;
            }
          }
        }
      }
    }
  }

  .learn-with-hubert-wrapper {
    @apply relative bg-gray-100 px-5 pt-[80px] mx-[-20px] pb-12;
    @apply sm:pt-[120px] sm:px-[60px] sm:rounded-2xl sm:mx-0;

    .illustration {
      @apply absolute hidden h-auto top-5 right-8;
      @apply sm:w-[140px] md:w-[200px] lg:w-[260px] sm:block;
    }

    .content-wrapper {
      @apply pt-12;

      .title-section {
        @apply flex items-center justify-between;
      }

      .article-card-wrapper {
        @apply grid grid-cols-12 gap-6 pt-8;

        .custom-article-card {
          @apply col-span-full lg:col-span-3;
        }
      }
    }
  }

  .tip-and-guide-wrapper {
    @apply grid grid-cols-2 gap-8 mt-6 lg:mt-12;

    .section {
      @apply col-span-full lg:col-span-1;
    }
  }
}

.new-candidate-modal-popup,
.interview-completed-modal {
  .head-section {
    @apply flex items-center;

    .icon-wrapper {
      @apply relative bg-success w-16 h-16 flex justify-center items-center rounded-full mr-5;

      svg {
        @apply absolute text-white;
      }
    }
  }

  .body-section {
    @apply mt-8;

    div {
      &:first-child {
        @apply mb-3;
      }
    }
  }

  .button-section {
    @apply flex justify-center mt-8;

    button {
      @apply w-full sm:w-auto;
    }
  }
}
