.registration-invite-email-wrapper {
  @apply relative rounded-2xl flex;
  @apply md:px-[60px] md:bg-violet-dark md:py-12;

  .left-section {
    @apply hidden flex-1 pr-8;
    @apply lg:block;

    h2 {
      @apply mb-1.5;
    }

    h2,
    h4,
    h5 {
      @apply text-white;
    }

    .benefits-box-wrapper {
      @apply hidden bg-gray-100 w-[350px] bottom-[80px] left-[calc(-350px+32px)] p-6 mt-12 rounded-2xl;
      @apply xl:bottom-[150px] lg:block;

      .benefit-box-title {
        @apply font-semibold text-lg mb-4;
      }

      .benefit-point {
        @apply flex items-center mb-2 text-sm;

        &:last-child {
          @apply mb-0;
        }

        span {
          @apply ml-3;
        }

        svg {
          @apply w-4;
        }
      }
    }
  }

  .right-section {
    @apply relative flex-1 rounded-2xl bg-white;
    @apply md:px-[60px] md:py-12;

    .hubert-logo-mini {
      @apply hidden w-12 mb-2;
      @apply md:block;
    }

    .registration-invite-email-form {
      @apply mt-8;
    }

    .terms-and-condition-text-wrapper {
      @apply mb-4;
    }
  }
}
