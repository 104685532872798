.your-interviews-wrapper {
  @apply sm:pt-5;

  .your-interviews-section-wrapper {
    @apply relative bg-neutral-purple px-5 pt-[80px] mx-[-20px] pb-12;
    @apply sm:pt-[120px] sm:px-[60px] sm:rounded-2xl sm:mx-0;

    .illustration {
      @apply absolute hidden h-auto top-5 right-8;
      @apply sm:w-[160px] lg:w-[200px] xl:w-[240px] sm:block;
    }

    .to-do-section-wrapper {
      @apply grid grid-cols-2 gap-5 justify-between pt-10;

      .to-do-section {
        @apply col-span-full xl:col-span-1 bg-white p-6 rounded-2xl;

        .section-body {
          @apply grid grid-cols-12;

          .left-body-section {
            @apply col-span-full sm:col-span-7;
          }

          .right-body-section {
            @apply col-span-full flex flex-col items-end justify-between pt-2;
            @apply sm:col-span-5 sm:pt-0;

            .created-at {
              @apply self-start sm:self-auto;
            }
          }

          &.no-ongoing-interviews {
            @apply flex items-start justify-start;

            img {
              @apply w-5 h-auto mr-3 mt-1;
            }
          }
        }
      }
    }
  }
}
