.help-center-wrapper {
  @apply sm:pt-5;

  .banner-section {
    @apply relative bg-gray-100 px-5 pt-[80px] mx-[-20px] pb-20;
    @apply sm:pt-[120px] sm:px-[60px] sm:rounded-2xl sm:mx-0;

    .illustration {
      @apply absolute hidden h-auto top-6 right-8;
      @apply sm:w-[160px] lg:w-[170px] xl:w-[240px] sm:block;
    }
  }

  .faq-section-wrapper {
    @apply my-12;

    .tab-selector-custom-wrapper {
      @apply text-center mb-6;
    }

    .content-wrapper {
      @apply xl:w-[1000px] mx-auto;

      .faq-item {
        @apply py-6 border-b border-gray-200 cursor-pointer;

        .question {
          @apply flex justify-between;
        }

        .answer {
          @apply bg-neutral-purple text-gray-700 mt-6 p-4 rounded-xl hidden;

          &.expanded {
            @apply block;
            animation: slide-in 250ms;
          }
        }

        @keyframes slide-in {
          from {
            transform: translateY(-100%);
            opacity: 0;
          }
          to {
            transform: translateY(0%);
            opacity: 1;
          }
        }
      }
    }
  }

  .still-have-question-wrapper {
    @apply text-center;

    .hubert-logo {
      @apply w-[120px] mx-auto;
    }
  }
}
