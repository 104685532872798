.hubert-dialog-container {
	&-enter {
		@apply scale-95 opacity-0;

		&-active {
			@apply scale-100 opacity-100;
		}

		&-done {
			@apply scale-100 opacity-100;
		}
	}

	&-exit {
		@apply scale-100 opacity-100;

		&-active {
			@apply scale-95 opacity-0;
		}

		&-done {
			@apply scale-95 opacity-0;
		}
	}
}

.hubert-dialog {
	height: 100dvh;
	width: 100dvw;
	max-width: 100dvw;
	max-height: 100dvh;
	@apply transition-all duration-300 ease-in-out;
	@apply p-0;
	// @apply bg-black; (Removed due to black bg in the model overlapping texts)

	&::backdrop {
		@apply bg-gray-900 opacity-70;
		@apply backdrop-blur;
	}

	iframe {
		padding-bottom: 0;
		overflow-y: scroll;
	}

	header {
		@apply rounded-full bg-black text-white drop-shadow-md;
		@apply flex justify-end;

		&[data-floating="true"] {
			position: fixed;
			top: calc((100dvh - 90%) / 3);
			right: 5px;
		}

		button {
			@apply flex  gap-1 py-2 px-2;
			translate: 0;
		}
	}
}
