.your-profile-wrapper {
  .column-wrapper {
    @apply mt-6 grid grid-cols-2 gap-8 lg:gap-5;

    &:last-child {
      @apply mt-8;
    }
  }

  .your-details-wrapper,
  .change-password-wrapper,
  .notification-preferences-wrapper,
  .account-removal-wrapper {
    @apply col-span-full lg:col-span-1;
  }

  .your-details-wrapper {
    .your-details-form {
      @apply mt-6;

      .name-field-wrapper {
        @apply sm:grid sm:grid-cols-2 sm:gap-5;
      }

      .phone-number-field-wrapper {
        @apply grid grid-cols-12 gap-5;

        .country-code-field-wrapper {
          @apply col-span-4 md:col-span-3;
        }

        .number-field-wrapper {
          @apply col-span-8 md:col-span-9;
        }
      }
    }
  }

  .change-password-wrapper {
    .change-password-form {
      @apply mt-6;
    }
  }

  .notification-preferences-wrapper {
    .toggler-wrapper {
      @apply inline-flex items-center mt-6 p-3 w-full bg-gray-100 rounded-md;

      .toggle-pill-container {
        @apply relative w-9 h-5 border border-gray-500 rounded-xl mr-3 duration-200;

        .toggle-pill {
          @apply absolute w-4 h-4 left-0.5 top-px bg-gray-500 rounded-full duration-200 ease-in;
        }

        &.toggled {
          @apply bg-violet border-violet-light;

          .toggle-pill {
            @apply translate-x-[calc(100%-2px)] bg-white;
          }
        }
      }
    }

    .toggle-help-text {
      @apply mt-3 text-gray-500;
    }
  }
}
