.login-wrapper {
  @apply relative rounded-2xl flex;
  @apply md:px-[60px] md:bg-violet-dark md:py-12;

  .left-section {
    @apply hidden flex-1 text-right pr-8 text-white;
    @apply lg:block;

    h2 {
      @apply mb-1.5;
    }

    .bg-image {
      @apply absolute w-[480px] xl:w-[600px] 2xl:w-[680px] left-3 bottom-0;
    }
  }

  .right-section {
    @apply flex-1 rounded-2xl bg-white;
    @apply md:px-[60px] md:py-12;

    .hubert-logo-mini {
      @apply hidden w-12 mb-2;
      @apply md:block;
    }

    .login-form {
      @apply mt-8;

      .forgot-password-link-wrapper {
        @apply w-full text-center mt-1;
      }
    }

    .or-section {
      @apply relative mb-4 mt-2 text-center;

      .or-seperator-line {
        @apply absolute top-[50%] h-px w-full bg-gray-300;
      }

      .or-text {
        @apply relative text-sm inline-block uppercase px-1.5 bg-white text-gray-500 z-10;
      }
    }
  }
}
