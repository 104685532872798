.btn,
.btn-link {
  @apply font-semibold rounded-3xl leading-normal duration-200;

  &.back-button {
    .button-content-wrapper {
      @apply px-0;
    }
  }

  .button-content-wrapper {
    @apply flex justify-center items-center py-2.5 px-5;
  }

  .button-img-icon {
    @apply w-4 mr-2;
  }
}

.btn {
  @apply bg-violet hover:bg-violet-dark text-white;
  &:disabled {
    pointer-events: none;
    @apply bg-gray-300 hover:bg-gray-200 text-gray-500 border border-gray-400;
  }

  &.btn-secondary {
    &:disabled {
      pointer-events: none;
      @apply bg-gray-300 hover:bg-gray-200 text-gray-500 border border-gray-400;
    }
    @apply bg-white hover:bg-gray-200 text-gray-900 border border-gray-900;
  }
}

.btn-link {
  @apply text-violet hover:text-violet-dark hover:underline;

  &.btn-secondary {
    @apply text-gray-900 hover:text-gray-900;
  }
}

.btn-sm,
.btn-link-sm {
  @apply text-sm;

  .button-content-wrapper {
    @apply py-1.5 px-3;
  }
}

.btn-lg,
.btn-link-lg {
  @apply text-lg;
}

.mobile-text-sm {
  @apply text-sm sm:text-base;
}
