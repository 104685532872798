@use "../../../styles/colors" as colors;
@use "../../../styles/media" as *;

@import url("https://fonts.googleapis.com/css2?family=Pacifico&family=Poppins:wght@500;600&family=Source+Sans+3&display=swap");

.font-pacifico {
	font-family: "Pacifico", cursive;
}

.navigation-bar {
	@apply sticky top-0 z-10 bg-white;
	@apply flex items-center justify-between;
	@apply w-full px-6 py-3.5;
}

.cover-image {
	@apply sticky top-0 -z-10 h-60 w-full object-cover md:hidden;
}

.job-landing-page {
	h1,
	h2,
	h3,
	h4,
	h5,
	h6 {
		font-family: "Poppins", sans-serif;
	}

	h1 {
		@apply text-2xl font-bold md:text-4xl;
	}

	h2 {
		@apply text-base font-bold md:text-lg;
	}

	p,
	span,
	li {
		@apply text-sm md:text-base;
	}

	article,
	.job-header {
		@apply mx-0 px-4 md:mx-auto xl:px-0;

		@include media(">tablet") {
			max-width: min(calc(100% - 2rem), 75rem);
		}
	}

	article {
		@apply pt-4 mb-14;

		background-color: white;
		padding-block-end: 5rem;

		section {
			margin-bottom: 1em;
		}

		section h2 {
			@apply mb-2;
		}

		section p {
			@apply flex flex-col gap-2;
		}

		ul {
			@apply ml-6 list-disc;
		}

		.alert {
			@apply flex flex-col justify-center items-start gap-4;
			@apply md:flex-row md:justify-start md:items-center md:gap-8;
			@apply bg-info-light/10;
			padding: 1em;
			border-radius: 8px;
			margin-bottom: 2em;

			h2 {
				@apply text-sm md:text-base font-bold;
				margin-bottom: 0.25em;
			}

			p {
				@apply text-sm md:text-base;
				margin-bottom: 0.5em;
			}

			.icon-container {
				@apply bg-info rounded-full;

				.icon {
					@apply h-10 w-10 md:h-12 md:w-12;
					@apply text-white rounded-full p-2;
				}
			}
		}
	}

	.apply-btn {
		@apply fixed bottom-8 left-4 right-4 lg:relative lg:bottom-auto;
		@apply py-2;
		@apply bg-violet text-white;
		@apply rounded-lg;
		@apply text-lg;
		@apply hover:bg-violet-dark;

		max-width: calc(100% - 2rem);
		@apply lg:max-w-xs;
	}
}

.job-header {
	position: sticky;
	top: -1px;
	@apply h-full w-full bg-white;
	@apply flex items-center justify-center md:justify-between;
	@apply py-8 md:py-12;
	@apply md:bg-white;
	transition: padding-top 0.1s ease-in;

	.key-info {
		@apply flex flex-col items-center justify-center gap-4 md:items-start;

		& > .highlight-group {
			@apply md:pl-2;
		}
	}

	.spotlight {
		@apply flex flex-wrap justify-center gap-6 sm:justify-start;
		@apply bg-neutral-purple;
		@apply rounded-lg;
		padding: 0.5rem 1rem;
	}

	@include media("tablet") {
		&[data-is-below-top="false"] {
			border-bottom: solid 1px colors.$gray-200;
			@apply pt-20 shadow-sm;
		}
	}
}

.highlight-group {
	@apply flex items-center gap-1;
}
