.page-not-found-wrapper {
  @apply text-center py-16;

  .text-404 {
    @apply text-9xl font-bold;
  }

  .title {
    @apply text-4xl font-light mb-12;
  }

  .description {
    @apply text-2xl mb-8;
  }
}
