h1 {
  @apply text-5xl;
}

h2 {
  @apply text-4xl;
}

h3 {
  @apply text-3xl;
}

h4 {
  @apply text-2xl;
}

h5 {
  @apply text-xl;
}

h1,
h2,
h3,
h4,
h5 {
  @apply font-semibold leading-normal;

  &.light {
    @apply font-light;
  }
}

.body-light,
.body-small-light,
.body-large-light {
  @apply font-light;
}

.body-medium,
.body-small-medium,
.body-large-medium {
  @apply font-medium;
}

.body-semibold,
.body-small-semibold,
.body-large-semibold {
  @apply font-semibold;
}

.body-small-regular,
.body-small-light,
.body-small-medium,
.body-small-semibold {
  @apply text-sm leading-normal;
}

.body-large-regular,
.body-large-light,
.body-large-medium,
.body-large-semibold {
  @apply text-lg leading-normal;
}

.body-regular,
.body-light,
.body-medium,
.body-semibold {
  @apply leading-normal;
}
