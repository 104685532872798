.login-invite-email-wrapper {
  @apply relative rounded-2xl flex;
  @apply md:px-[60px] md:bg-violet-dark md:py-12;

  .left-section {
    @apply hidden flex-1 pr-8;
    @apply lg:block;

    h2 {
      @apply mb-1.5;
    }

    h2,
    h4,
    h5 {
      @apply text-white;
    }

    .benefits-box-wrapper {
      @apply hidden bg-gray-100 w-[350px] bottom-[80px] left-[calc(-350px+32px)] p-6 mt-12 rounded-2xl;
      @apply xl:bottom-[150px] lg:block;

      .benefit-box-title {
        @apply font-semibold text-lg mb-4;
      }

      .benefit-point {
        @apply flex items-center mb-2 text-sm;

        &:last-child {
          @apply mb-0;
        }

        span {
          @apply ml-3;
        }

        svg {
          @apply w-4;
        }
      }
    }
  }

  .right-section {
    @apply flex-1 rounded-2xl bg-white;
    @apply md:px-[60px] md:py-12;

    .hubert-logo-mini {
      @apply hidden w-12 mb-2;
      @apply md:block;
    }

    .login-form {
      @apply mt-8;

      .forgot-password-link-wrapper {
        @apply w-full text-center mt-1;
      }
    }

    .or-section {
      @apply relative mb-4 mt-2 text-center;

      .or-seperator-line {
        @apply absolute top-[50%] h-px w-full bg-gray-300;
      }

      .or-text {
        @apply relative text-sm inline-block uppercase px-1.5 bg-white text-gray-500 z-10;
      }
    }
  }
}

.login-interview-completed-invite-email-wrapper {
  .interview-completed-alert-wrapper {
    @apply flex flex-col items-center text-center justify-center mb-12;
    @apply md:flex-row md:items-start md:text-left;

    .icon-wrapper {
      @apply flex items-center justify-center w-[72px] h-[72px] mt-4 mr-6 bg-success text-white 
        rounded-full;
    }

    .text-content-wrapper {
      @apply w-full md:w-[600px] mt-4 md:mt-0 block;
    }
  }
}
