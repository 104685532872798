.pre-interview-page-wrapper {
	.content-wrapper {
		@apply max-w-[700px] text-center mx-auto;
	}

	.header-section {
		@apply mb-4 md:mb-8;
	}

	.tip-box-wrapper {
		@apply px-10 py-8 bg-violet text-white rounded-2xl text-left;

		.tips-wrapper {
			@apply flex flex-col gap-2 md:px-5 pt-4 md:pt-6;

			.tip-wrapper {
				@apply flex items-center;

				&:last-child {
					@apply pb-0;
				}

				.icon {
					@apply w-6 h-6;
				}

				span {
					@apply w-full ml-3;
				}
			}
		}
	}

	.ready-to-start-container {
		@apply w-full flex flex-col gap-0.5 my-4;

		> * {
			margin-block-end: 1em;
		}
	}

	.check-advice-link-wrapper {
		@apply my-4;
	}

	.or-section {
		@apply relative my-4 text-center;

		.or-seperator-line {
			@apply absolute top-[50%] h-px w-full bg-gray-300;
		}

		.or-text {
			@apply relative text-sm inline-block uppercase px-1.5 bg-white text-gray-500 z-10;
		}
	}

	.apply-btn {
		@apply py-2;
		@apply bg-violet text-white;
		@apply text-lg;
		@apply hover:bg-violet-dark hover:text-white hover:no-underline;

		max-width: calc(100% - 2rem);
		@apply lg:max-w-xs;
		border-radius: 0.5rem;
	}
}
