.hubert-tip-wrapper,
.article-link-card-wrapper {
  @apply pt-6;

  .head-section {
    @apply flex items-center mb-4;

    h4 {
      @apply leading-none;
    }
  }

  .hubert-tip-icon,
  .briefcase-icon {
    @apply w-12 h-12 mr-4;
  }
}

.article-link-card-wrapper {
  @apply block bg-violet-extralight px-8 pb-10 lg:pb-0 h-full rounded-2xl;

  .body-section {
    @apply grid grid-cols-12 gap-6 mt-20;

    h4 {
      @apply col-span-9;
      @apply lg:col-span-9 xl:col-span-10;
    }

    .arrow-icon-wrapper {
      @apply col-span-3 flex justify-end;
      @apply lg:col-span-3 xl:col-span-2;

      .arrow-icon {
        @apply w-16 h-16;
      }
    }
  }
}

.latest-job-postings-wrapper {
  @apply mt-12 mb-12;

  .skeleton-loading {
    @apply flex lg:flex-row;

    .skeleton-loading-element {
      @apply m-6 rounded-2xl;
    }
  }

  .job-postings-carousel {
    @apply px-8;
  }

  .slick-arrow {
    &:hover {
      @apply fill-gray-100;
    }
  }

  .slick-prev {
    @apply left-0;
  }

  .slick-next {
    @apply right-0;
  }

  .job-posting-card {
    @apply bg-white p-6 m-4 rounded-2xl border border-gray-200 h-full;
    @apply flex justify-between flex-col;
    @apply lg:flex-row;

    &:hover {
      @apply bg-gray-50;
    }

    .body {
      @apply w-full;
    }
  }
}

.your-interviews-table-wrapper {
  @apply py-12;

  .title-section {
    @apply flex items-center justify-between;
  }

  .interviews-table {
    @apply table-fixed w-full mt-8;

    thead {
      @apply text-left border-b border-gray-300;

      th {
        @apply pb-2.5;
      }
    }

    td {
      @apply py-4;

      &:last-child {
        @apply text-right;
      }
    }
  }

  .interview-box-wrapper {
    @apply py-6 border-b border-gray-300 sm:flex sm:justify-between;

    .first-section {
      @apply flex-[6] flex flex-col;

      div {
        @apply mb-2;

        &:last-child {
          @apply pb-2;
        }
      }

      span:last-child {
        @apply mt-2;
      }
    }

    .second-section {
      @apply flex-auto self-end text-right pl-6;
    }
  }
}

.article-card {
  @apply border-b border-gray-300 pb-4;

  img {
    @apply w-full rounded-2xl object-cover;
  }

  .article-image {
    @apply h-[320px] lg:h-[220px] w-full;
  }

  .article-tag {
    @apply my-4;

    span {
      @apply text-xs font-medium uppercase py-2 px-3 tracking-wider bg-gray-300 rounded-md;
    }
  }
}

.old-redirection-modal-wrapper {
  .head-section {
    @apply flex items-center justify-center;

    .info-icon {
      @apply w-[60px] h-auto;
    }
  }

  .body-section {
    @apply mt-8;

    div {
      &:first-child {
        @apply mb-3;
      }
    }
  }

  .button-section {
    @apply flex justify-center mt-8;

    button {
      @apply w-full sm:w-auto;
    }
  }
}
