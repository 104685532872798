.btn-anchor {
    @apply text-white font-semibold py-2.5 px-5 bg-violet hover:bg-violet-dark rounded-3xl leading-normal duration-200 select-none;
    &.disabled {
        pointer-events: none;
        @apply bg-gray-300 hover:bg-gray-200 text-gray-500 border border-gray-400;
      }
    
      &.btn-secondary {
        &.disabled {
          pointer-events: none;
          @apply bg-gray-300 hover:bg-gray-200 text-gray-500 border border-gray-400;
        }
        @apply bg-white hover:bg-gray-200 text-gray-900 border border-gray-900;
    }
}

.link {
  @apply font-semibold rounded-3xl leading-normal duration-200 underline hover:text-violet;
  &-sm {
    @apply text-sm;
  }

  &-lg {
    @apply text-lg;
  }
}


