.interview-wrapper {
  .interview-details-wrapper {
    @apply mt-8;

    .content-section {
      @apply mt-6;
    }
  }

  .your-score-wrapper {
    @apply mt-12;

    .assessed-match-wrapper {
      @apply flex flex-col p-8 mt-8 border border-gray-200 rounded-2xl;
      @apply md:flex-row;

      .illustration {
        @apply w-[100px] md:w-[150px] h-full mx-auto;

        &.skeleton-loading {
          @apply w-[100px] h-[100px] md:w-[180px] md:h-[140px] mx-auto;
        }
      }

      .content {
        @apply w-full md:ml-8;

        h2 {
          @apply mt-4;
        }

        h2,
        .button-wrapper {
          @apply text-center md:text-left;
        }

        .button-wrapper {
          button {
            @apply w-full sm:w-auto;
          }
        }
      }

      .assessed-match-text-wrapper {
        @apply hidden md:block;
      }
    }

    .score-boxes-wrapper {
      @apply grid grid-cols-12 lg:gap-6;
    }

    .score-box-wrapper {
      @apply mt-8 px-8 py-6 border border-gray-200 rounded-2xl col-span-12 lg:col-span-6;

      .title-section {
        @apply flex items-center my-6;

        .score-icon {
          @apply mr-3 w-8 h-auto;
        }
      }

      .btn-container {
        @apply text-right mt-4;

        button {
          @apply pr-0;
        }
      }
    }
  }

  .script-from-interview-wrapper {
    @apply mt-8 px-8 py-6 border border-gray-200 rounded-2xl;

    .button-wrapper {
      @apply mt-5;

      button:first-child {
        @apply mr-2 mb-3 sm:mb-0;
      }

      button {
        @apply w-full sm:w-auto;
      }
    }
  }

  .gray-bg-wrapper {
    @apply mt-8 p-8 mx-[-20px] bg-gray-100;
    @apply sm:rounded-2xl sm:mx-0;
  }

  .how-to-improve-at-interviews-wrapper {
    .question-wrapper {
      @apply mb-6 pb-6 border-b border-gray-300;

      &:last-child {
        @apply mb-0;
      }

      ul,
      ol {
        @apply ml-8 my-1.5 font-normal;

        strong,
        b {
          @apply font-normal;
        }
      }

      ul {
        @apply list-disc;
      }

      ol {
        @apply list-decimal;
      }

      strong {
        @apply font-semibold;
      }
    }

    .custom-alert {
      .content {
        @apply pl-0 sm:pl-2.5;
      }

      svg {
        @apply hidden sm:block;
      }

      .alert-link-button {
        svg {
          @apply inline;
        }
      }
    }

    .latest-job-postings-wrapper {
      @apply mb-0;
    }
  }

  .similar-offers-wrapper {
    @apply mt-8;

    .job-offers-wrapper {
      @apply grid grid-cols-1 gap-5;
      @apply xl:grid-cols-4;
    }

    .job-offer-wrapper {
      @apply p-6 bg-white rounded-2xl;

      .job-offer-proceed-wrapper {
        @apply flex justify-end mt-2;

        img {
          @apply w-8 h-8;
        }
      }
    }
  }
}

.iframe-modal-popup {
  @apply flex flex-col;
  height: calc(100vh - 80px);

  iframe {
    @apply w-full h-full pt-2;
  }
}

.how-hubert-scores-modal-popup {
  @apply h-[calc(100vh-80px)] overflow-auto;

  .scores-main-wrapper {
    @apply py-10 grid grid-cols-none sm:grid-cols-12 gap-8 sm:gap-6;

    .score-wrapper {
      @apply col-span-full sm:col-span-6;

      .head-section {
        @apply flex mb-3;

        img {
          @apply w-6 h-6 mr-3;
        }
      }
    }
  }

  .assessed-match {
    .scores-main-wrapper {
      .score-wrapper {
        @apply col-span-full sm:col-span-6 xl:col-span-3;
      }
    }
  }

  .timeline-wrapper {
    @apply py-10 px-3 md:px-14 md:py-10;

    .timeline-event {
      @apply relative flex pb-8;

      &:last-child {
        @apply pb-0;
      }

      .knob {
        @apply relative h-6 w-6 mr-8 mt-1 bg-violet rounded-full z-10;
      }

      .timeline-line {
        @apply w-px h-full left-3 top-1 bg-violet-light absolute;
      }
    }
  }
}
