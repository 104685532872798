.forgot-password-wrapper {
  @apply relative rounded-2xl flex;
  @apply md:px-[60px] md:bg-violet-dark md:py-12;

  .left-section {
    @apply hidden flex-1 text-right pr-8 text-white;
    @apply lg:block;

    h2 {
      @apply mb-1.5;
    }

    .bg-image {
      @apply absolute w-[200px] xl:w-[220px] 2xl:w-[240px] left-[160px] xl:left-[180px] bottom-12;
    }
  }

  .right-section {
    @apply flex-1 rounded-2xl bg-white;
    @apply md:px-[60px] md:py-12;

    .hubert-logo-mini {
      @apply hidden w-12 mb-2;
      @apply md:block;
    }

    .forgot-password-form-wrapper {
      @apply mt-8;

      .sign-in-instead-button-wrapper {
        @apply w-full text-center mt-4;

        .custom-btn {
          @apply p-0 ml-1.5;
        }
      }
    }
  }
}
