.learn-with-hubert-wrapper {
  @apply sm:pt-5;

  .banner-section {
    @apply relative bg-gray-100 px-5 pt-[80px] mx-[-20px] pb-20;
    @apply sm:pt-[120px] sm:px-[60px] sm:rounded-2xl sm:mx-0;

    .illustration {
      @apply absolute hidden h-auto top-6 right-4;
      @apply sm:w-[140px] md:right-8 md:w-[180px] lg:w-[200px] 2xl:w-[260px] sm:block;
    }
  }

  .custom-tab-selector-wrapper {
    @apply mt-8 mb-8 text-center;
  }

  .newest-on-your-feed-wrapper {
    @apply mb-8;

    .content-wrapper {
      @apply grid grid-cols-2 gap-8 mt-6;

      .image-wrapper {
        @apply col-span-full md:col-span-1;

        .article-image {
          @apply h-[360px] w-full object-cover rounded-2xl;
        }
      }

      .content {
        @apply col-span-full md:col-span-1 flex flex-col justify-center;

        h3 {
          @apply my-4;
        }
      }

      .article-tag {
        span {
          @apply text-xs font-medium uppercase py-2 px-3 tracking-wider bg-gray-300 rounded-md;
        }
      }
    }
  }

  .grid-items-wrapper {
    @apply grid grid-cols-12 gap-6 mt-6;

    .custom-article-card {
      @apply col-span-full md:col-span-6 xl:col-span-3;
    }

    &.skeleton-wrapper {
      @apply h-[320px] lg:h-[220px] mt-1;
    }
  }
}
