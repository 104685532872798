.unsubscribe-wrapper {
  @apply text-center lg:w-[896px] xl:w-[1024px] mx-auto mt-5;

  .illustration {
    @apply h-[150px] w-auto mx-auto my-8;
  }

  .reasons-wrapper {
    @apply mt-4 mb-8;

    form {
      .radios-wrapper {
        @apply mt-6 mb-8 min-w-[300px] text-left inline-block;

        .form-radio {
          &:last-child {
            @apply mb-0;
          }
        }

        .textbox-wrapper {
          @apply relative w-[calc(100%-26px)] left-[26px] mt-3;

          .form-group {
            @apply mb-2;
          }

          .form-label {
            @apply hidden;
          }
        }
      }
    }
  }
}
