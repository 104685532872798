.horizontal-line {
  @apply h-px mt-1.5;

  &.light {
    @apply bg-gray-200;
  }

  &.regular {
    @apply bg-gray-300;
  }

  &.medium {
    @apply bg-gray-500;
  }

  &.semi-dark {
    @apply bg-gray-700;
  }

  &.dark {
    @apply bg-gray-900;
  }
}

.large-alert-wrapper {
  @apply flex flex-col items-center text-center mt-20;

  &.loading {
    svg {
      @apply text-violet;
    }
  }

  &.success {
    svg {
      @apply text-success;
    }
  }

  &.info {
    svg {
      @apply text-info;
    }
  }

  &.warning {
    svg {
      @apply text-warning;
    }
  }

  &.danger {
    svg {
      @apply text-danger;
    }
  }

  h3 {
    @apply mt-6 mb-3;
  }
}

.alert-wrapper {
  &.success {
    svg {
      @apply text-success;
    }
  }

  &.info {
    svg {
      @apply text-info;
    }
  }

  &.warning {
    svg {
      @apply text-warning;
    }
  }

  &.danger {
    svg {
      @apply text-danger;
    }
  }

  .content-wrapper {
    @apply inline-flex bg-white py-3 px-4 rounded-lg border border-gray-100;

    .content {
      @apply pl-2.5;

      .alert-link-button {
        .button-content-wrapper {
          @apply p-0;
        }

        svg {
          @apply text-gray-900;
        }
      }
    }
  }
}

.modal-wrapper {
  @apply w-[calc(100vw-60px)] sm:w-[540px] bg-white p-12 rounded-2xl;

  &.large {
    @apply w-[calc(100vw-60px)] md:w-[750px] lg:w-[896px] xl:w-[1024px] p-12;
  }

  .close-icon {
    @apply absolute right-4 top-4 cursor-pointer;
  }
}

.no-scroll {
  @apply overflow-hidden h-full;
}

.tool-tip-container {
  @apply absolute bottom-0 flex flex-col items-center hidden mb-10 group-hover:flex;
}

.tool-tip-message {
  @apply relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md;
}

.tool-tip {
  &.wrapper {
    @apply relative flex flex-col;
  }

  &.message-container {
    @apply absolute bottom-0 flex flex-col items-center hidden mb-10 group-hover:flex;
  }

  &.message {
    @apply relative z-10 p-2 text-xs leading-none text-white whitespace-nowrap bg-gray-600 shadow-lg rounded-md;
  }
}

.or-section {
  @apply relative my-4 text-center;

  .or-seperator-line {
    @apply absolute top-[50%] h-px w-full bg-gray-300;
  }

  .or-text {
    @apply relative text-sm inline-block uppercase px-1.5 bg-white text-gray-500 z-10;
  }
}

.skeleton-loading {
  flex-grow: 1;
}

.tab-selector-wrapper {
  @apply relative flex my-6 border-b border-gray-300 text-center sm:text-left;

  &.hug-content {
    @apply inline-block my-0;
  }

  .tab-wrapper {
    @apply inline-block transition duration-150 pb-2.5 border-b border-transparent hover:border-violet;

    &.active {
      @apply border-violet;
    }

    .tab {
      @apply inline-block cursor-pointer px-5 mt-4 sm:mt-0;

      &:first-child {
        @apply mt-0;
      }
    }
  }

  &.link-variant {
    .tab-wrapper {
      @apply hover:text-violet;

      &.active {
        @apply text-violet;
      }
    }
  }

  &.pill-variant {
    .tab-wrapper {
      &.active {
        .tab {
          @apply bg-neutral-purple;
        }
      }

      .tab {
        @apply py-2.5 rounded-3xl hover:bg-neutral-purple;
      }
    }
  }
}
