$violet: #7543ff;
$violet-dark: #5f38cc;
$violet-light: #9a76ff;
$violet-extralight: #c8b4ff;
$success: #20c997;
$success-dark: #229a77;
$success-light: #5eefc4;
$info: #3194ff;
$info-dark: #0068d8;
$info-light: #6eb3ff;
$warning: #ffc107;
$warning-dark: #ffa800;
$warning-light: #ffd65a;
$danger: #f95f5f;
$danger-dark: #d44a4a;
$danger-light: #ff8484;

$white: #fff;
$black: #000;

$gray-50: #f9fafb;
$gray-100: #f3f4f6;
$gray-200: #e5e7eb;
$gray-300: #d1d5db;
$gray-400: #f95f5f;
$gray-500: #6b7280;
$gray-600: #4b5563;
$gray-700: #374151;
$gray-800: #1f2937;
$gray-900: #111827;

$neutral-blue: #f9fafb;
$neutral-purple: #f6f3ff;

:root {
  --toastify-color-success: #{$success};
  --toastify-color-info: #{$info};
  --toastify-color-warning: #{$warning};
  --toastify-color-error: #{$danger};
}
