@use "tailwindcss/base";
@use "tailwindcss/components";
@use "tailwindcss/utilities";

@use "./fonts";
@use "./colors";
@use "./texts";
@use "./buttons";
@use "./forms";
@use "./elements";
@use "./common";
@use "./link";
@use "./media";

body {
	font-family: "Suisse Int'l", sans-serif;
	@apply h-full font-normal text-gray-900;
	@apply bg-white;
}

.container {
	@apply relative mb-10;
}
