.registration-wrapper {
  @apply relative rounded-2xl flex;
  @apply md:px-[60px] md:bg-violet-dark md:py-12;

  .left-section {
    @apply hidden flex-1 text-right pr-8 text-white;
    @apply lg:block;

    h2 {
      @apply mb-1.5;
    }

    .bg-image {
      @apply absolute w-[400px] xl:w-[480px] left-10 bottom-0;
    }

    .benefits-box-wrapper {
      @apply hidden bg-gray-100 max-w-[480px] p-6 rounded-2xl text-gray-900 text-left mt-16 mx-auto;
      @apply lg:block;

      .benefit-box-title {
        @apply font-semibold text-xl mb-6;
      }

      .benefit-point {
        @apply flex items-center mb-3 text-xl font-light;

        &:last-child {
          @apply mb-0;
        }

        span {
          @apply ml-4;
        }

        svg {
          @apply w-5;
        }
      }
    }
  }

  .right-section {
    @apply relative flex-1 rounded-2xl bg-white;
    @apply md:px-[60px] md:py-12;

    .hubert-logo-mini {
      @apply hidden w-12 mb-2;
      @apply md:block;
    }

    .registration-form {
      @apply mt-8;
    }

    .terms-and-condition-text-wrapper {
      @apply mb-4;
    }

    .or-section {
      @apply relative my-4 text-center;

      .or-seperator-line {
        @apply absolute top-[50%] h-px w-full bg-gray-300;
      }

      .or-text {
        @apply relative text-sm inline-block uppercase px-1.5 bg-white text-gray-500 z-10;
      }
    }
  }
}
