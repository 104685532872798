@use './../../styles/colors' as *;

.header-wrapper {
  @apply relative flex justify-between items-center py-5;

  .hubert-logo {
    @apply w-36 h-auto;
  }

  .right-side-section-wrapper {
    @apply flex items-center;
  }

  nav {
    &.navbar-desktop,
    &.navbar-mobile {
      a {
        @apply font-light px-4 text-gray-900;

        &.active {
          @apply font-medium;
        }

        &:hover {
          @apply underline;
        }
      }
    }

    &.navbar-desktop {
      @apply absolute w-[600px] p-6 text-center translate-x-[-63%] xl:translate-x-[-50%] translate-y-0 left-1/2;
    }

    &.navbar-mobile {
      @apply flex flex-col pt-2;

      a {
        @apply text-2xl pb-6;
      }
    }
  }

  .menu-icon,
  .close-icon {
    @apply ml-6 hover:text-violet duration-150 cursor-pointer;
  }

  .language-user-wrapper {
    @apply flex items-center;
  }

  .language-selector {
    @apply mr-6;

    .custom__control {
      @apply rounded-3xl bg-gray-100 min-h-[34px] px-3 py-0 border-0 cursor-pointer text-sm;

      .custom__value-container {
        @apply p-0 pr-0.5;
      }

      .custom__indicator {
        @apply p-0;

        svg {
          @apply text-gray-400;
        }
      }

      &:hover {
        box-shadow: 0 0 0 1px $violet;

        .custom__indicator {
          svg {
            @apply text-violet;
          }
        }
      }
    }

    .custom__control--is-focused,
    .custom__control--menu-is-open {
      box-shadow: 0 0 0 1px $violet;

      .custom__indicator {
        svg {
          @apply text-violet;
        }
      }
    }

    .custom__menu {
      @apply rounded-xl text-sm;

      .custom__menu-list {
        @apply py-0 rounded-xl;
      }

      .custom__option {
        @apply cursor-pointer;
      }

      .custom__option--is-focused {
        @apply bg-gray-200;
      }

      .custom__option--is-selected {
        @apply bg-violet;
      }
    }
  }

  .user-dropdown-wrapper {
    @apply relative;

    .user {
      @apply flex font-medium duration-150;

      &:hover {
        @apply text-violet;
      }

      &.expanded {
        @apply text-violet;
      }
    }

    .dropdown {
      @apply absolute hidden w-[150px] p-3 z-10 right-0 top-7 bg-gray-100 
      border border-gray-300 rounded-xl font-medium;

      &.expanded {
        @apply block;
      }

      .my-profile,
      .log-out {
        @apply flex items-center;

        svg {
          @apply mr-2;
        }

        &:hover {
          @apply text-gray-600;
        }
      }

      .my-profile {
        @apply pb-2;
      }

      .log-out {
        @apply font-normal text-gray-700 pt-2;
      }

      .horizontal-line {
        @apply h-px bg-gray-300;
      }
    }
  }

  .user-mobile-wrapper {
    @apply px-4 py-6 text-2xl;

    div {
      @apply font-semibold pb-6 text-violet;
    }

    a {
      @apply block font-light pb-4;

      &:hover {
        @apply underline;
      }
    }
  }

  .mobile-nav-expanded-wrapper {
    @apply absolute z-50 top-0 left-[-20px] px-5 w-screen h-screen bg-white overflow-y-auto pb-[25vh];
    animation: 0.3s fade-in;

    .head-section {
      @apply flex items-center justify-between py-5;
    }

    .close-icon {
      @apply fixed right-5 top-8;
    }

    .language-selector {
      @apply mr-0;
    }

    .language-mobile-wrapper {
      @apply pl-4 py-4 max-w-xl;
    }

    .horizontal-line {
      @apply max-w-xl;
    }
  }
}

@keyframes fade-in {
  0% {
    opacity: 0;
    transform: translateX(100px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}
