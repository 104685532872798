/* 
*  To use simply use the following syntax in your CSS
*  
*   @include media("key-from-$condMap") {
*     // Your CSS here
*   }
*
*/

$media-mobile: 640px;
$media-tablet: 768px;
$media-desktop: 1024px;
$media-desktop-xl: 1280px;
$media-desktop-xxl: 1536px;

@function translate-media-condition($c) {
	$condMap: (
		"screen": "only screen",
		"print": "only print",
		"mobile": "(max-width: #{$media-mobile})",
		">mobile": "(min-width: #{$media-mobile})",
		"tablet": "(max-width: #{$media-tablet})",
		">tablet": "(min-width: #{$media-tablet})",
		"desktop": "(max-width: #{$media-desktop})",
		"desktop-xl": "(max-width: #{$media-desktop-xl})",
		"desktop-xxl": "(max-width: #{$media-desktop-xxl})",
		"no-motion": "(prefers-reduced-motion: reduce)",
	);
	@return map-get($condMap, $c);
}

// The mdia mixin
@mixin media($args...) {
	$query: "";
	@each $arg in $args {
		$op: "";
		@if ($query != "") {
			$op: " and ";
		}
		$query: $query + $op + translate-media-condition($arg);
	}
	@media #{$query} {
		@content;
	}
}
