@font-face {
    font-family: "Suisse Int'l";
    src: url("./../assets/fonts/SuisseIntl-Light.ttf");
    font-weight: 300;
}

@font-face {
    font-family: "Suisse Int'l";
    src: url("./../assets/fonts/SuisseIntl-Regular.ttf");
    font-weight: 400;
}

@font-face {
    font-family: "Suisse Int'l";
    src: url("./../assets/fonts/SuisseIntl-Book.otf");
    font-weight: 450;
}

@font-face {
    font-family: "Suisse Int'l";
    src: url("./../assets/fonts/SuisseIntl-Medium.otf");
    font-weight: 500;
}

@font-face {
    font-family: "Suisse Int'l";
    src: url("./../assets/fonts/SuisseIntl-SemiBold.ttf");
    font-weight: 600;
}