.article-page-wrapper {
  .heading-section {
    @apply w-full xl:w-[800px] py-12 mx-auto;

    .article-tag {
      @apply pb-6;

      span {
        @apply text-xs font-medium uppercase py-2 px-3 tracking-wider bg-gray-300 rounded-md;
      }
    }
  }

  .image-section {
    .article-image {
      @apply h-[450px] w-full object-cover rounded-3xl mb-8;
    }
  }

  .share-and-author-section {
    @apply flex justify-end mt-3;

    .icon-wrapper {
      @apply flex;

      svg {
        @apply mr-4 duration-150 cursor-pointer hover:text-violet;
      }
    }
  }

  .article-content-section {
    @apply mt-8 max-w-[800px] mx-auto text-lg md:text-xl;

    h1,
    h2,
    h3 {
      @apply mb-4;
    }

    h4,
    h5 {
      @apply mb-2;
    }

    blockquote {
      @apply text-3xl leading-normal border-opacity-50 border-t border-b border-violet-light py-6 mb-8;
    }

    p,
    ul,
    ol {
      @apply mt-5;
    }

    ul {
      @apply list-disc;
    }

    ol {
      @apply list-decimal;
    }

    ul,
    ol {
      @apply ml-8 lg:ml-16;

      li {
        @apply mt-1.5;
      }
    }

    img {
      @apply my-8;
    }

    .btn {
      border-radius: 1.5rem !important;
      @apply py-2.5 px-5 inline-block;
    }
  }
}
